<template>
    <create-new-category
        :error-text="errorText"
        mode="create"
        @on-save-btn-click="saveCategory($event)"
        @input="resetError"
    />
</template>

<script>
import CreateNewCategory from '@/components/create-category/CreateNewCategory';
import categoryBaseMixins from '@/mixins/content/categoryBaseMixins';

export default {
    name: 'IndexVue',
    components: {
        CreateNewCategory
    },
    mixins: [categoryBaseMixins]
}
</script>

<style lang="scss" scoped>

</style>
